// dev
export const environment = {
    production: true,
    firebase: {
        vapidKey: 'BBgy06670qgjmktBevTk21mldz0jVANCupgWpCIOtbDqU7Ds3uvf6e9H0LZxOdNUFPPsQRm5ceAzOgPuDNey10g',
        apiKey: 'AIzaSyARky-VIGnhepRDC66tyXj1bfqQAFG-P3Q',
        authDomain: 'dianovatime.firebaseapp.com',
        databaseURL: 'https://dianovatime.firebaseio.com',
        projectId: 'dianovatime',
        storageBucket: 'dianovatime.appspot.com',
        messagingSenderId: '375052070938',
        appId: '1:375052070938:web:3c895c3cccc4fc467021a3',
        measurementId: 'G-RTJRMKYSJP'
    }
};
// prod
// export const environment = {
//     production: true,
//     firebase: {
//         vapidKey:'BPWZgUQv1qZz3R2_xEqvmHcGbts19LXnv0PjOdPO3CUP_rNXXHROm8rkMQgzsN_jg-25tpz-O33z7557efeQjOQ',
//         apiKey: "AIzaSyBgpOI8Z1G-qFVIGMCfZDChKemCf7rMtuI",
//         authDomain: "reviziegazprod-37da9.firebaseapp.com",
//         databaseURL: "https://reviziegazprod-37da9.firebaseio.com",
//         projectId: "reviziegazprod-37da9",
//         storageBucket: "reviziegazprod-37da9.appspot.com",
//         messagingSenderId: "359468529735",
//         appId: "1:359468529735:web:8450fcafe3a5ae53f45962",
//         measurementId: "G-VX959JZF2S"
//     }
// };

