import { Injectable, Output, EventEmitter } from '@angular/core'

@Injectable()
export class PredictionEventsService {

    @Output() change: EventEmitter<boolean> = new EventEmitter();

    toggle(field_map) {

        this.change.emit(field_map);
    }

}
