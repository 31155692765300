import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './common/services/guard/auth/auth.guard';

const routes: Routes = [
    {path: '', loadChildren: () => import('./login/login.module').then(m => m.LoginModule)},
    {path: 'forgot', loadChildren: () => import('./forgot/forgot.module').then(m => m.ForgotModule)},
    {
        path: 'project',
        loadChildren: () => import('./project/project.module').then(m => m.ProjectModule),
        canActivate: [AuthGuard]
    },  {
    path: 'registration',
    loadChildren: () => import('./registration/registration.module').then( m => m.RegistrationPageModule)
  },

];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
