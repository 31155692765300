import {Component, TemplateRef, ViewChild} from '@angular/core';
import {NgbPopover} from '@ng-bootstrap/ng-bootstrap';

@Component({
    template: `
      <div class="fc-content" [ngbPopover]="template" [autoClose]="false" [closeDelay]="1000" container="body" triggers="manual">
        <ng-content></ng-content>
      </div>
    `,
})
export class CalendarEventPopoverComponent {
    template: TemplateRef<any>;

    @ViewChild(NgbPopover, {static: true}) popover: NgbPopover;
}
