import {AfterViewInit, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {AlertController, Platform} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import 'firebase/auth';
import {NotificationsService} from './common/services/notificationService/notifications.service';
import {SwUpdate} from '@angular/service-worker';
import {AngularFireDatabase} from "@angular/fire/database";
import {Router, NavigationEnd} from "@angular/router";

declare let gtag: any;

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
    connected = true;

    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private cdr: ChangeDetectorRef,
        private swUpdate: SwUpdate,
        private angularFireDatabase: AngularFireDatabase,
        private alertController: AlertController,
        private notificationsService: NotificationsService,
        private router: Router
    ) {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                gtag('config', 'G-JFVHVBX0CC',
                    {
                        page_path: event.urlAfterRedirects
                    }
                );
            }
        });
        this.notificationsService.init().then(() => {
            this.initializeApp();
            this.platform.ready().then(async (readySource) => {
                await this.notificationsService.requestPermission();
            });
            if (this.swUpdate.isEnabled) {
                this.swUpdate.available.subscribe(async () => {
                    const alert = await this.alertController.create({
                        header: `Actualizare!`,
                        message: `O noua versiune este disponibila!`,
                        buttons: [
                             {
                                text: 'Actualizati',
                                handler: () => {
                                    window.location.reload();
                                },
                            },
                        ],
                    });
                    await alert.present();
                });
            }
        });
    }

    async ngOnInit() {
    }

    initializeApp() {
        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
            this.splashScreen.hide();
        });
    }

    checkOnline() {
        const connectedRef = this.angularFireDatabase.database.ref(".info/connected");
        connectedRef.on("value", (snap) => {
            this.connected = snap.val() === true;
            this.cdr.detectChanges();
        });
    }

    ngAfterViewInit() {
        /*https://github.com/firebase/firebase-js-sdk/issues/249#issuecomment-394428458*/
        setTimeout(() => {
            this.checkOnline();
        }, 1);
    }
}
